<template>
  <div style="display: inline-block" class="filter-item line menu-btn-item" >
    <ColorTextBtn type="primary" size="mini" @click="to">
      定制器备注
    </ColorTextBtn>
    <EForm
      ref="form"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :data="data"
      @submit.native.prevent
      v-if="dialogVisible"
      title="定制器备注"
    />
  </div>
</template>

<script>
import EForm from "./form";
import { commonEheaderMixin } from "@/mixins";
import ColorTextBtn from '@/components/base/colorTextBtn';
export default {
  components: { ColorTextBtn, EForm },
  mixins: [commonEheaderMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  methods: {
    async to () {
      this.showDialog()
      await this.$nextTick()
      const findItem = $GET(this.data, 'referSizeList', []).find(item => item.id === this.data.referSizeId)
      this.$refs.form.form.designSuggestions = $GET((findItem|| {}), 'designSuggestions', '')
    }
  }
};
</script>

<style>
</style>